.input_wrapper {
  @apply relative flex items-center bg-white rounded-base h-input border border-solid border-gray p-0 transition-all;
}
.input_wrapper input:-webkit-autofill:hover,
.input_wrapper input:-webkit-autofill:focus,
.input_wrapper input:-webkit-autofill:active {
  @layer auto_fill;
}
.input_wrapper input:-internal-autofill-selected {
  @layer auto_fill;
}