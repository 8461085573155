.input_label {
  @apply text-font text-sb font-semibold block ml-1 mb-1.5 capitalize;
}

.input_label_dynamic {
  @apply absolute top-1/2 left-1 ml-0 -translate-y-1/2 z-10 text-sb font-normal bg-transparent text-gray select-none pointer-events-none p-0.5 leading-tight transition-all text-ellipsis overflow-hidden whitespace-nowrap;
}

.input_label_dynamic_focused {
  @apply -top-[0.03rem] left-1 text-xs font-semibold bg-white text-main;
}
