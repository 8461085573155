.input_box {
  @apply bg-transparent border-none text-font text-sb p-1.5 block w-full h-full transition-all rounded-base box-border;
  appearance: textfield;
}

.input_box:focus {
  @apply border-none border-0 outline-0;
}

.input_box:disabled {
  @apply bg-grey bg-opacity-10 shadow-inner;
}

.input_box::placeholder {
  @apply text-sb;
}

.input_box::-webkit-outer-spin-button,
.input_box::-webkit-inner-spin-button {
  @apply appearance-none m-0;
}

.input_box::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.input_box::-webkit-search-cancel-button {
  -webkit-appearance: none;
  display: none;
}

.input_box[type="number"] {
  -moz-appearance: textfield;
}
