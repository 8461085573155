@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* color */
  --color-page-bg: #f1f1f4;
  --color-font: #1F1D1D;

  /* font */
  --font-main: 1rem;
}

/* @media (prefers-color-scheme: dark) {
  :root {}
} */

html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
em,
img,
strong,
form,
table,
section {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  box-sizing: border-box;
  line-height: 1;
}

html,
body {
  width: 100%;
  scroll-behavior: smooth;
}

section,
footer,
header,
menu,
nav {
  display: block;
}

input {
  box-sizing: border-box;
}

body {
  line-height: 1.15;
  -webkit-text-size-adjust: none;
  vertical-align: baseline;
  font-family: "Roboto", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
  box-sizing: border-box;

  font-size: var(--font-main);
  background-color: rgb(var(--color-page-bg));
  color: rgb(var(--color-font));

  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ol,
ul {
  list-style: none;
}

a,
button {
  cursor: pointer;
}

a:link {
  text-decoration: none !important;
}

a {
  font-size: inherit;
  text-decoration: none;
  color: inherit;
}
a:hover {
  color: inherit;
}

svg {
  width: 1rem;
  height: 1rem;
}

.teko_font {
  font-family: "Teko", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  letter-spacing: 0.015em;
}

.roboto_font {
  font-family: "Roboto", sans-serif;
  font-style: normal;
}
